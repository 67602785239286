@media screen and (min-width: 1025px) {
    #exhibitions {
        margin-top: 5rem;
        margin-bottom: 100px;

    }

    .aboutGrid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 25px
    }

    .aboutTxt {
        margin-top: 30px;
    }
}

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {

    #exhibitions {
        margin-bottom: -2rem;
    }

    .aboutGrid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .aboutTxt p {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        text-align: justify;
        margin-bottom: 1rem;
    }

    .aboutTxt br {
        display: none;
    }
}