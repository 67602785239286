footer {
    margin-top: 7rem;
}

.footMsj {
    border: 1px solid whitesmoke;
    width: 100%;
    padding: 20px;
    margin-top: -60px;
    background-color: var(--color-bg-1);


}

.footMsj input {
    margin-bottom: 20px;
    width: 25%;
    height: 24px;
    background-color: var(--color-light);
    border-radius: 5px;
    color: var(--color-bg-2);
    font-family: "Montserrat";

}

.footer__container {
    border-top: 0.5rem solid var(--color-bg-4);
    padding-top: 5rem;
}

.footer__head {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.footer__btn {
    width: 5rem;
    height: 5rem;
    border: 0.2rem solid var(--color-primary-variant);
    border-radius: 50%;
    font-size: 3rem;
    color: var(--color-primary-variant);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition);
}

.footer__btn:hover {
    transform: translateY(-1rem);
    border-color: var(--color-primary);
}

.footer__links {
    margin: 3rem 0 5rem;
    display: flex;
    align-items: center;
    gap: 5rem;
}

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {
    .footer_links_container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .footer__links {
        gap: 1rem;
        text-align: center;
    }

    .footer_es {
        color: rgb(240, 112, 0);
    }
}