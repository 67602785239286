/* ========================= NAV ========================= */
@media screen and (min-width: 1025px) {
    nav {
        height: 6.5rem;
        width: 100vw;
        display: grid;
        place-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
    }

    /* nav class on scroll using javascript */
    .window-scrolled {
        background: var(--color-bg-2);
        border-bottom: 0.2rem solid var(--color-bg-3);
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
    }

    .nav__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* only need on tablets and phones */

    .nav__logo {
        width: 9rem;
        margin-top: 12px;
    }

    .nav__links {
        display: flex !important;
        gap: 4rem;
    }

    .nav__socials {
        display: flex;
        gap: 1rem;
    }

    .nav__socials a {
        width: 2rem;
        height: 2rem;
        background: linear-gradient(var(--color-primary-variant), var(--color-primary));
        border-radius: 0.5rem;
        color: var(--color-bg-4);
        display: grid;
        place-items: center;
    }

    .nav__socials a:hover {
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.4);
    }

    .nav__toggle-btn {
        display: none !important;
    }
}

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {
    nav {
        height: 6.5rem;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
    }

    .window-scrolled {
        background: var(--color-bg-2);
        border-bottom: 0.2rem solid var(--color-bg-3);
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
    }

    .nav__container {
        align-items: center;
    }

    .nav__logo {
        width: 9rem;
        margin-top: 12px;
    }

    .nav__logo p {
        text-align: center;
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        display: none;
        perspective: 400px;
    }

    .nav__links li {
        height: 5rem;
        box-shadow: -3rem 3rem 3rem rgba(0, 0, 0, 0.7);
        animation: navAnimation 800ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .nav__links li a {
        background: var(--color-bg-4);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
        border-top: 1px solid var(--color-bg-2);
    }

    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }

    .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }

    .nav__links li:nth-child(4) {
        animation-delay: 600ms;
    }

    .nav__links li:nth-child(5) {
        animation-delay: 800ms;
    }

    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }

    /* Clases para animación de cierre */
    .nav__links.reverse li {
        animation: navAnimationReverse 800ms ease backwards;
    }


    .nav__links.reverse li:nth-child(1) {
        animation-delay: 800ms;
    }

    .nav__links.reverse li:nth-child(2) {
        animation-delay: 600ms;
    }

    .nav__links.reverse li:nth-child(3) {
        animation-delay: 400ms;
    }

    .nav__links.reverse li:nth-child(4) {
        animation-delay: 200ms;
    }

    @keyframes navAnimationReverse {
        from {
            transform: rotateX(0);
            opacity: 1;
        }

        to {
            transform: rotateX(90deg);
            opacity: 0;
        }
    }

    .nav__socials {
        display: flex;
        gap: 1rem;
    }

    .nav__socials a {
        width: 2rem;
        height: 2rem;
        background: linear-gradient(var(--color-primary-variant), var(--color-primary));
        border-radius: 0.5rem;
        color: var(--color-bg-4);
        display: grid;
        place-items: center;
    }

    .nav__toggle-btn {
        display: inline-block;
        font-size: 2rem;
        background: transparent;
        cursor: pointer;
        color: var(--color-white);
    }

    #nav__toggle-close {
        display: none;
    }
}