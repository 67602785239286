:root {
  /* ========== GREEN THEME ========== */
  --color-primary: #ff7b02;
  --color-primary-variant: #e5a55d;
  --color-bg-1: #000e17;
  --color-bg-2: #001e2d;
  --color-bg-3: #012734;
  --color-bg-4: #003347;
  --color-light: #e8ecee;
  --color-white: hsl(0, 0%, 100%);
  /* ========== PURPLE THEME ========== */
  /* --color-primary: #ff7b02;
  --color-primary-variant: #e5a55d;
  --color-bg-1: #140021;
  --color-bg-2: #1e0032;
  --color-bg-3: #25003e;
  --color-bg-4: #36005a;
  --color-light: #d0b8e0;
  --color-white: hsl(0, 0%, 90%); */

  --container-width-lg: 88%;
  --container-width-md: 92%;

  --transition: all 400ms ease;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background: rgb(229, 165, 93, 0.2);
}

::-webkit-scrollbar-thumb {
  width: 100%;
  background: var(--color-primary-variant);
  border-radius: 0.25rem;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: var(--color-bg-3) url('./assets/images/bg-texture.png');
  color: var(--color-light);
  line-height: 1.7;
  overflow-x: hidden;
}

h1,
h2 {
  line-height: 1.1;
  font-weight: 400;
}

h1 {
  font-size: 4rem;
  color: var(--color-white);
}

h2 {
  font-size: 3.5rem;
}

a {
  color: var(--color-light);
  transition: var(--transition);
}

a:hover {
  color: var(--color-primary);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  max-width: 2160px;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

/*============================= MEDIA QUERIES (medium screens) =============================*/
/* @media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 1.3;
  }

  h2 {
    font-size: 2.5rem;
  }
} */

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {

  * {
    margin: 0rem;
    padding: 0rem;
    border: 0rem;
    outline: 0rem;
    list-style: none;
    text-decoration: none;
  }

  h1,
  h2 {
    font-size: 2rem;
    line-height: 1.3;
    color: var(--color-white);
  }

  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0rem;
  }

  .container {
    width: 100%;
    margin: auto;
    width: var(--container-width-md);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}