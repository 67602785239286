@media screen and (min-width: 1025px) {
    #pellet-header {
        padding: 0;
        padding-bottom: 6rem;
        max-width: 100%;
        height: fit-content;
        position: relative;
        top: 0;
        background: linear-gradient(var(--color-bg-3), var(--color-bg-1));
        text-align: left;
        overflow: hidden;
    }

    /*--------------PELLET HEADER FIRST SECTION--------------*/

    .pellet-header-first-section {
        display: grid;
        grid-template-columns: 43% 53%;
        gap: 4%;
        margin-top: 5rem;
        position: relative;
        height: auto;
        padding: 1.5rem;
    }

    #pellet-title {
        font-size: 6rem;
    }

    #pellet-title,
    #pellet-subtitle {
        text-align: center;
        font-family: 'Bangers', cursive;
        padding: 1rem;
    }

    .section-left p {
        text-align: center;
    }

    .imageFrame img {
        height: 15rem;
        max-height: fit-content;
        border-radius: 10px;
        margin-top: 2rem;
        box-shadow: 0 3.5rem 4rem rgba(0, 0, 0, 0.5);
    }

    /*--------------PELLET HEADER SECOND SECTION--------------*/

    .pellet-container {
        text-align: center;
        padding: 10px;
        margin-top: 0rem;
    }

    .pellet-container h1 {
        font-size: 5rem;
    }

    .pellet-grid {
        display: flex;
        margin-top: 1rem;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 20px;
        /* background: radial-gradient(circle, var(--color-bg-4) 0%, #000000 100%); */
        border-radius: 10px;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }

    .pellet-item {
        flex: 1 1 200px;
        max-width: 260px;
        text-align: center;
        margin: 5px;
        margin-bottom: 1px;
        padding: 10px;
        padding-bottom: 1px;
        box-sizing: border-box;
    }

    .pellet-item img {
        padding: 2px;
        /* max-width: 200px;
        max-height: 260px; */
        object-fit: cover;
        object-position: center;
    }

    #pellet-img-1,
    #pellet-img-2,
    #pellet-img-3,
    #pellet-img-4 {
        aspect-ratio: 3/4;
        transform: scale(0.9);
    }

    .pellet-item h2 {
        font-family: 'Bangers', cursive;
        font-size: 2em;
        margin: 10px 0;
    }

    .pellet-item-title {
        border: 3px solid #000000;
        border-radius: 10px;
        /* background: radial-gradient(circle, #ffffff 0%, var(--color-bg-4) 100%); */
        background: radial-gradient(circle, #f2b900 0%, #f20000 100%);
        color: #000000;
        margin: 5px;
    }


    .pellet-item-img {
        height: auto;
        justify-content: center;
        justify-items: center;
        margin: auto;
    }

    .pellet-item h2 {
        font-family: 'Bangers', cursive;
        font-size: 2em;
        /* Ajustar el tamaño del texto */
        margin: 10px 0;
    }

    .contact__btn i {
        font-size: 3.5rem;
        position: absolute;
        height: 100px;
        width: 100px;
    }

    .contact__btn p {
        font-size: 1rem;
        font-weight: 600;
        width: 10rem;
        height: 10rem;
        display: flex;
        justify-content: center;
        animation: spinText 30s linear infinite;
    }

    .contact__btn p span {
        position: absolute;
        transform-origin: 0.3rem 5rem;
    }

    #wsp_contact_btn {
        bottom: -9rem;
    }
}

@media screen and (max-width: 1024px) {

    #pellet-header {
        padding: 0;
        padding-bottom: 6rem;
        max-width: 100%;
        height: fit-content;
        position: relative;
        top: 0;
        background: linear-gradient(var(--color-bg-3), var(--color-bg-1));
        text-align: left;
        overflow: hidden;
    }

    .pellet-header-first-section {
        display: flex;
        flex-direction: column;
        gap: 4%;
        margin-top: 5rem;
        position: relative;
        height: auto;
        padding: 1.5rem;
    }

    #pellet-title {
        font-size: 3.5rem;
    }

    #pellet-subtitle {
        font-size: 2.5rem;
    }

    #pellet-title,
    #pellet-subtitle {
        text-align: center;
        font-family: 'Bangers', cursive;
        padding: 1rem;
    }

    .section-left p {
        text-align: center;
    }

    .imageFrame img {
        height: 15rem;
        max-height: fit-content;
        border-radius: 10px;
        margin-top: 2rem;
        box-shadow: 0 3.5rem 4rem rgba(0, 0, 0, 0.5);
    }

    /*--------------PELLET HEADER SECOND SECTION--------------*/

    .pellet-container {
        padding: 20px;
        text-align: center;
        margin-top: 0rem;
    }

    .pellet-container h1 {
        font-size: 5rem;
    }

    .pellet-grid {
        display: flex;
        margin-top: 1rem;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 20px;
        /* background: radial-gradient(circle, var(--color-bg-4) 0%, #000000 100%); */
        border-radius: 10px;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }

    .pellet-item {
        flex: 1 1 200px;
        max-width: 260px;
        text-align: center;
        margin: 5px;
        margin-bottom: 1px;
        padding: 10px;
        padding-bottom: 1px;
        box-sizing: border-box;
        /* background: linear-gradient(149deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 219, 0, 0.7) 37%, rgba(207, 0, 0, 0.7) 77%, rgba(0, 0, 0, 0.7) 100%);
        border-radius: 10px;
        border: 3px solid #000000; */
    }

    .pellet-item img {
        padding: 2px;
        /* max-width: 200px;
        max-height: 260px; */
        object-fit: cover;
        object-position: center;
    }

    #pellet-img-1,
    #pellet-img-2,
    #pellet-img-3,
    #pellet-img-4 {
        aspect-ratio: 3/4;
        transform: scale(0.9);
    }

    .pellet-item h2 {
        font-family: 'Bangers', cursive;
        font-size: 2em;
        margin: 10px 0;
        color: #000000 !important;
    }

    .pellet-item-title {
        border: 3px solid #000000;
        border-radius: 10px;
        /* background: radial-gradient(circle, #ffffff 0%, var(--color-bg-4) 100%); */
        background: radial-gradient(circle, #f2b900 0%, #f20000 100%);
        margin: 5px;
    }

    .pellet-item-img {
        height: auto;
        justify-content: center;
        justify-items: center;
        margin: auto;
    }

    .pellet-item h2 {
        font-family: 'Bangers', cursive;
        font-size: 2em;
        /* Ajustar el tamaño del texto */
        margin: 10px 0;
    }
}