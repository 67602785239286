/* ========================= GALLERY ========================= */
@media screen and (min-width: 1025px) {
    #gallery {
        margin-top: 4rem;
    }

    .swiper-wrapper {
        margin-top: 5rem;
        height: 37rem;
        padding-bottom: 5rem;
    }

    .swiper-slide {
        border: 0.5rem solid var(--color-bg-4);
        height: fit-content;
        max-height: 36rem;
        overflow: hidden;
        transition: var(--transition);
    }

    .swiper-slide:hover {
        box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.4);
    }

    .swiper-slide img {
        filter: saturate(0);
        transition: var(--transition);
    }

    .swiper-slide:hover img {
        filter: saturate(1)
    }

    .swiper-pagination-bullet {
        background: var(--color-primary);
    }

    /* ========================= EXHIBITIONS ========================= */

    .exhibitions__container {
        position: relative;
        margin-top: 6px;
    }

    .exhibitions__head {
        display: grid;
        grid-template-columns: auto 10rem;
        justify-content: space-between;
        align-items: center;
    }

    .exhibitions__head>p {
        width: 45rem;
    }

    .exhibitions__btn {
        color: var(--color-primary-variant);
    }

    .exhibitions__btn:hover {
        transform: translateY(-1rem);
    }

    .exhibitions__gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 2rem;
        gap: 3rem;
    }

    .exhibitions__gallery article {
        border: 0.5rem solid var(--color-bg-4);
        transition: var(--transition);
    }

    .exhibitions__gallery article:nth-child(1) {
        grid-row: 1/3;
    }

    .exhibitions__gallery article:nth-child(4) {
        grid-column: 2/4;
        grid-row: 2/3;
    }

    .exhibitions__gallery article img {
        height: 100%;
        filter: saturate(1);
        transition: var(--transition);
        box-shadow: 0 4rem 4rem rgba(0, 0, 0, 0.5);
    }

    .exhibitions__gallery article:hover img {
        filter: saturate(1);
    }

    /* .exhibitions__gallery article:hover {
        box-shadow: 0 4rem 4rem rgba(0, 0, 0, 0.5);
    } */
}

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {
    #gallery {
        margin-top: 6rem;

    }

    .exhibitions__head>p {
        margin-top: 1rem;
        transform: scale(1);
        width: 100%;
        text-align: justify;
    }

    .exhibitions__gallery {
        display: block;
    }

    .exhibitions__gallery article {
        width: 100%;
        height: fit-content;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    #Facility_1 {
        display: none;
    }

}