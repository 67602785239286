/* ========================= PRODUCTS ========================= */

/*============================= MEDIA QUERIES (large screens) =============================*/
@media screen and (min-width: 1025px) {
    #about {
        margin-top: 18rem;
    }

    .specs {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;

    }

    .specsin {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 10px
    }

    .flags {
        text-align: center;
        margin-top: -6px;
    }

    .about__container {
        display: grid;
        grid-template-columns: 5% 45% 40%;
        gap: 3rem;
        position: relative;

    }

    .product {
        margin-top: 120px;
    }

    .productDetail {
        margin-top: 30px;
        height: auto;
        border: 2px solid var(--color-light);
        padding: 20px;
        background-color: var(--color-bg-1);
        border-radius: 10px;
        color: var(--color-light)
    }


    #product_Pellet_anchor {
        color: yellow;
    }

    .about__title {
        position: absolute;
        top: 22rem;
        z-index: 1;
    }

    .about__btn {
        color: var(--color-primary-variant);
        align-self: end;
    }

    .about__btn:hover {
        transform: translateY(-1rem);
        color: var(--color-primary);
    }

    .about__image {
        position: relative;
        margin-bottom: 5rem;
    }

    .about__image-bg {
        width: 16rem;
        height: 24rem;
        position: absolute;
        bottom: 0;
        background: var(--color-bg-4);
    }

    .about__image-lg {
        position: relative;
        left: 1rem;
        bottom: 1rem;
        filter: saturate(0) brightness(0.3);
        transition: 500ms ease;
    }

    .about__image-sm {
        position: absolute;
        top: 3rem;
        left: 8rem;
        height: 22rem;
        width: 22rem;
        overflow: hidden;
        box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.2);
        transition: 500ms ease;
        filter: saturate(3) brightness(0.8);
    }

    .about__right {
        margin-left: 5rem;
    }

    .about__right p {
        margin: 0 4rem 1.5rem 0;
    }

    .res_layout {
        display: none;
    }

    .product_Trim_Boards {
        margin-top: 4rem;
    }
}

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {
    .about__container {
        display: none !important;
    }

    .res_layout {
        display: flex;
        flex-direction: column;
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .about__title {
        position: relative;
        display: inline-block;
        padding: 20px;
        width: 100%;
        overflow: hidden;
        text-align: center;
        font-size: 8vw;
        border-radius: 30px;
        background: none;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: -6rem;
        color: rgb(255, 255, 255) !important;
    }

    .about__title::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/about.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.6;
        z-index: -1;
    }

    .about__title br {
        display: none;
    }

    #shipping {
        position: flex;
        width: 100%;
        text-align: justify;
        margin-bottom: 2rem;
    }

    .product_Door_Components {
        width: 100%;
        text-align: justify;
    }

    .product_Trim_Boards {
        text-align: justify;
        margin-top: 30px;
    }

    .product_Window_Components {
        text-align: justify;
        margin-top: 30px;
    }

    .product_Pellet {
        text-align: justify;
        margin-top: 30px;
    }

    #product_Pellet_anchor {
        color: yellow;
    }

    .productDetail {
        margin-top: 30px;
        height: auto;
        border: 2px solid var(--color-light);
        padding: 20px;
        background-color: var(--color-bg-1);
        border-radius: 10px;
        color: var(--color-light)
    }

    #opt_spec {
        margin-bottom: -5rem;
    }

}