/* ========================= HEADER ========================= */
.videoFrame {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.videoFrame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1025px) {
    header {
        max-width: 100vw;
        height: 100vh;
        position: relative;
        top: 0;
        background: linear-gradient(var(--color-bg-3), var(--color-bg-1));
        overflow: hidden;
        text-align: left;
    }

    .header__container {
        display: grid;
        grid-template-columns: 43% 53%;
        gap: 4%;
        margin-top: 10rem;
        position: relative;
    }

    .header__image-bg {
        background: var(--color-bg-4);
        height: 22rem;
        width: 16rem;
        position: absolute;
    }

    .header__image-lg {
        width: 29rem;
        position: relative;
        top: 1rem;
        left: 1rem;
        filter: saturate(0) brightness(0.3);
        transition: var(--transition);
    }

    .header__image-sm {
        width: 28rem;
        height: 28rem;
        overflow: hidden;
        position: absolute;
        left: 6rem;
        top: 6rem;
        transition: var(--transition);
    }

    .header__left:hover .header__image-sm {
        opacity: 0;
    }

    .header__left:hover .header__image-lg {
        filter: saturate(1) brightness(1);
        border: 1rem solid var(--color-bg-4);
        top: 0;
        left: 0;
    }


    .header__head {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
    }

    /* general empty styles */
    .empty {
        height: 3.5rem;
        width: 18rem;
        background: var(--color-bg-2);
        border-radius: calc(3.5rem / 2);
        margin-bottom: 3.5rem;
        box-shadow: inset 0 1rem 1rem rgba(0, 0, 0, 0.3);
        border: 1px solid var(--color-bg-4);
    }

    .header__tag {
        color: var(--color-primary-variant);
        transition: var(--transition);
    }

    .header__tag:hover {
        color: var(--color-primary);
    }

    .header__right p {
        margin-top: 2rem;
        width: 85%;
    }

    /* only shows on tablets and phones */
    .header__btn-md {
        display: none;
    }

    .header__frames {
        position: absolute;
        top: 88vh;
        right: 47rem;
        transition: var(--transition);
    }

    .header__frame {
        width: 14rem;
        border: 0.4rem solid var(--color-bg-3);
        box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5);
        position: absolute;
        transform: rotate(-10deg);
        transform-origin: bottom left;
        transition: var(--transition);
    }

    .header__frame:nth-child(2) {
        transform: rotate(20deg);
        top: -2rem;
        left: 2rem;
    }

    .header__frames:hover .header__frame {
        transform: rotate(0);
    }

    .header__frames:hover .header__frame:nth-child(2) {
        top: 0;
        left: 15rem;
    }


    /* general style for contact buttons */
    .contact__btn {
        color: var(--color-bg-1);
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        cursor: pointer;
        transition: var(--transition);
    }

    .header__btn {
        background: linear-gradient(-30deg, var(--color-primary-variant), var(--color-primary), var(--color-primary-variant));
        position: absolute;
        right: 6%;
        bottom: -3rem;
    }

    .header__btn:hover {
        box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.3);
        transform: translateY(-1rem);
        color: var(--color-bg-1)
    }

    .contact__btn i,
    .contact__btn-icon {
        font-size: 3.5rem;
        position: absolute;
    }

    .contact__btn p {
        font-size: 1rem;
        font-weight: 600;
        width: 10rem;
        height: 10rem;
        display: flex;
        justify-content: center;
        animation: spinText 30s linear infinite;
    }

    .contact__btn p span {
        position: absolute;
        transform-origin: 0.3rem 5rem;
    }

    @keyframes spinText {
        to {
            transform: rotate(360deg);
        }
    }
}

/*============================= MEDIA QUERIES (small screens) =============================*/
@media screen and (max-width: 1024px) {
    header {
        padding: 0;
        padding-bottom: 6rem;
        max-width: 100%;
        height: 100vh;
        position: relative;
        top: 0;
        background: linear-gradient(var(--color-bg-3), var(--color-bg-1));
        text-align: left;
        overflow: hidden;
    }

    .header__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0;
        text-align: center;
        padding: 0 1em;
    }

    .header__head {
        justify-content: center;
        margin-top: 100px;
        width: 100%;
        padding: 1em;
    }

    .header__tag {
        color: var(--color-primary-variant);
        transition: var(--transition);
    }

    .header__left h1 {
        width: 100%;
    }

    .header__left p {
        padding: 0 1rem;
        text-align: justify;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: -5vw;
    }

    .header__right {
        margin-top: 4rem;
    }

    .videoFrame {
        transform: scale(1);
        max-width: 100%;
    }

    .header__frames,
    .contact__btn {
        display: none;
    }

}